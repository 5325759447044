/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.ant-table {
  line-height: 1;
}

.ant-table-thead>tr>th {
  text-align: center;
  overflow-wrap: unset;
  height: 75px;
}

.ant-table-body {
  min-height: fit-content;
}

.ant-table-tbody>tr>td {
  overflow-wrap: break-word;
  min-height: fit-content;
}

.anticon {
  vertical-align: 0.1em;
}

.ant-form-item-label {
  white-space: normal;
}

.logo {
  text-align: center;
  height: 80px;
  margin: 15px 5px 30px 5px;
}

.logo img {
  height: 100%;
}

.ant-layout-sider {
  flex: 0 0 210px !important;
  max-width: 210px !important;
  min-width: 210px !important;
  width: 210px !important;
}

.ant-layout-sider-collapsed {
  flex: 0 0 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important;
}

.cursorPointer {
  cursor: pointer;
}

.custom-antd-number-input {
  width: 100%;
}